import React from "react"
import { JsonLd } from "react-schemaorg"
import { FAQPage, Thing } from "schema-dts"
import Provider from "models/Provider"
import { BankOrCreditUnion, BreadcrumbList } from "schema-dts"

export const compileSchemaOrgQa = (data: string) => {

  let questions: string[] = []
  const qMatches = data.match(/<(summary|question)>(.|\n)*?<\/(summary|question)>/g)
  if (qMatches) {
    questions = qMatches.map(function (s: string) {
      return s.replace(/<\/?question>/g, '').replace(/<\/?summary>/g, '')
    })
  }

  let answers: string[] = []
  const aMatches = data.match(/<(LongAnswer|answer)>(.|\n)*?<\/(LongAnswer|answer)>/g)
  if (aMatches) {
    answers = aMatches.map(function (s: string) {
      let answer = s.replace(/(\r\n|\n|\r)/gm, " ")
      answer = answer.replace(/(<([^>]+)>)/ig, "")
      answer = answer.replace(/__/g, "")
      let matches = answer.match(/\[(.*?)\]\((?:https?):\/\/[\n\S]+\)/g)
      if (matches) {
        matches.map(m => {
          const match = m.match(/\[(.*?)\]/)
          if (match !== null) {
            answer = answer.replace(m, match[1])
          }
        })
      }
      return answer.trim()
    })
  }


  const mainEntity: SchemaValue<Thing | IdReference, "mainEntity"> = []
  if (questions.length === answers.length) {
    for (let i = 0; i < questions.length; i++) {
      // const answerHtml = ReactDOMServer.renderToStaticMarkup(<ReactMarkdown children={answers[i]} />)
      // let htmlNode = null
      // if (typeof window !== 'undefined' && !window.document) {
      //   htmlNode = document.createElement("div")
      //   htmlNode.innerHTML = answerHtml
      //   htmlNode.querySelectorAll("*").forEach(function (node) {
      //     node.removeAttribute("style")
      //     node.removeAttribute("class")
      //   })
      // }
      const entity = {
        "@type": "Question",
        name: questions[i],
        acceptedAnswer: {
          "@type": "Answer",
          text: answers[i],
        },
      }
      mainEntity.push(entity)
    }
  }

  return (
    <JsonLd<FAQPage>
      item={{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: mainEntity,
      }}
    />
  )
}

export const compileSchemaOrgFaq = (data: string) => {

  // find all "details" blocks
  let details: string[] = []
  const dMatches = data.match(/<details>(.|\n)*?<\/details>/g)
  if (dMatches) {
    details = dMatches.map(function (s: string) {
      return s.replace(/<\/?details>/g, '')
    })
  }

  // find the question and answer(s) for each details block
  let qa: {question: string, answer: string}[] = []

  details.forEach(d => {
    let question = ""
    let shortAnswer = ""
    let longAnswer = ""

    const qMatches = d.match(/<(summary|question)>(.|\n)*?<\/(summary|question)>/g)
    if (qMatches && qMatches.length === 1) {
      question = qMatches[0].replace(/<\/?question>/g, '').replace(/<\/?summary>/g, '')
    } else {
      return
    }

    const saMatches = d.match(/<ShortAnswer>(.|\n)*?<\/ShortAnswer>/g)
    if (saMatches && saMatches.length === 1) {
      shortAnswer = saMatches[0]
      shortAnswer = shortAnswer.replace(/(\r\n|\n|\r)/gm, " ")
      shortAnswer = shortAnswer.replace(/(<([^>]+)>)/ig, "")
      shortAnswer = shortAnswer.replace(/__/g, "")
      shortAnswer = shortAnswer.replace(/#+ /g, "")
    }

    const laMatches = d.match(/<(LongAnswer|answer)>(.|\n)*?<\/(LongAnswer|answer)>/g)
    if (laMatches && laMatches.length === 1) {
      longAnswer = laMatches[0]
      longAnswer = longAnswer.replace(/<OutsideSchemaAnswer>(.|\n)*?<\/OutsideSchemaAnswer>/g, "")
      longAnswer = longAnswer.replace(/(\r\n|\n|\r)/gm, " ")
      longAnswer = longAnswer.replace(/(<([^>]+)>)/ig, "")
      longAnswer = longAnswer.replace(/__/g, "")
      longAnswer = longAnswer.replace(/#+ /g, "")
      let matches = longAnswer.match(/\[(.*?)\]\((?:https?):\/\/[\n\S]+\)/g)
      if (matches) {
        matches.map(m => {
          const match = m.match(/\[(.*?)\]/)
          if (match !== null) {
            longAnswer = longAnswer.replace(m, match[1])
          }
        })
      }
    }

    shortAnswer = shortAnswer.trim()
    longAnswer = longAnswer.trim()
    
    shortAnswer = (shortAnswer ? "Summary: " + shortAnswer : "")
    longAnswer = ((shortAnswer && longAnswer) ? "More information: " + longAnswer : longAnswer)

    let answer = shortAnswer + " " + longAnswer
    answer = answer.trim()

    if (question && answer) {
      qa.push({question: question, answer: answer})
    }
  })

  const mainEntity: SchemaValue<Thing | IdReference, "mainEntity"> = []
  qa.forEach(e => {
    const entity = {
      "@type": "Question",
      name: e.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: e.answer,
      },
    }
    mainEntity.push(entity)
  })

  return (
    <JsonLd<FAQPage>
      item={{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: mainEntity,
      }}
    />
  )
}

export const makeSchemaOrgProvider = (data: Provider, type: string): JSX.Element => {
  const name = type == "hl" ? "Home Loans" : "Credit Cards"
  const url_end = type == "hl" ? "home-loans" : "credit-cards"
  return (
    <div>
      <JsonLd<BreadcrumbList>
        item={{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: name,
              item: "https://www.stayorgo.com.au/" + url_end,
            },
            {
              "@type": "ListItem",
              position: 2,
              name: data.name,
              item: "https://www.stayorgo.com.au/" + data.urlSlug + "-" + url_end,
            },
          ],
        }}
      />
      <JsonLd<BankOrCreditUnion>
        item={{
          "@context": "https://schema.org",
          "@type": "BankOrCreditUnion",
          brand: data.name,
          name: data.name,
          // logo: providerImage(data.id),
          // url: "https://www.stayorgo.com.au",
          location: "Australia",
          // description: data.getAutoDescription(),
        }}
      />
    </div>
  )
}