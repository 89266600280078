import Layout from "components/layout"
import Markdown from "components/markdown"
import { Typography } from "components/typography"
import React, { useEffect } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { JsonLd } from "react-schemaorg"
import { NewsArticle } from "schema-dts"
import { compileSchemaOrgQa } from "components/schema"
import * as styles from "./style.module.scss"
import AppManager from "services/app-manager"
import mustache from "mustache"
import { graphql, useStaticQuery } from "gatsby"
import { getImagesFromMarkdown } from "components/utils"

function getGlobalReference() {
  const queryResult = useStaticQuery(graphql`
    {
      allContentfulArticleReferenceReferenceJsonNode {
        nodes {
          internal {
            content
          }
        }
      }
    }
  `)

  return queryResult
}

const ArticleTemplate = (props: any) => {
  // const options = {
  //   renderNode: {
  //     [BLOCKS.EMBEDDED_ASSET]: ({
  //       data: {
  //         target: { fields },
  //       },
  //     }) => `<img src="${fields.file.url}" height="${300}" width="${400}" alt="${fields.description}"/>`,
  //   },
  // }
  const articles = [JSON.parse(props.pageContext.p).article]

  useEffect(() => {
    const tables = document.getElementsByTagName("table")
    if (tables) {
      // cr: https://stackoverflow.com/questions/55483961/automatically-append-data-labels-based-content-of-th-to-corresponding-td-for
      for (let i = 0; i < tables.length; i++) {
        let bodyTrCollection = tables[i].querySelectorAll("tbody tr")
        let th = tables[i].querySelectorAll("th")
        let thCollection = Array.from(th)

        for (let i = 0; i < bodyTrCollection.length; i++) {
          let td = bodyTrCollection[i].querySelectorAll("td")
          let tdCollection = Array.from(td)
          for (let j = 1; j < tdCollection.length; j++) {
            if (tdCollection[j].innerHTML) {
              let headerLabel = thCollection[j].innerHTML
              tdCollection[j].setAttribute("data-label", headerLabel)
            }
          }
        }
      }
    }
  })

  const makeSchemaOrg = (article: any) => {
    const imgUrls = article.longText.longText.match(/\(\/\/images.ctfassets.net\/.*\)/)
    if (imgUrls) {
      for (let i = 0; i < imgUrls.length; i++) {
        imgUrls[i] = imgUrls[i].substring(1, imgUrls[i].length - 1)
      }
    }

    return (
      <div>
        <JsonLd<NewsArticle>
          item={{
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": typeof window !== "undefined" ? window.location.href : "",
            },
            headline: article.title,
            ...(imgUrls && { image: imgUrls }),
            datePublished: article.createdAt,
            dateModified: article.updatedAt,
            author: article.author
              ? {
                  "@type": "Person",
                  name: article.author.name,
                  url: article.author.profilePicture ? article.author.profilePicture.localFile.url : "/images/Logo_SOG_Colour.png",
                }
              : {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  name: "Stay or Go",
                  url: "https://www.stayorgo.com.au/",
                  logo: "/images/Logo_SOG_Colour.png",
                  location: "Australia",
                  description: "With Stay or Go, you no longer need to switch banks for a better deal.",
                },
            publisher: {
              "@type": "Organization",
              name: "Stay or Go",
              logo: {
                "@type": "ImageObject",
                url: "/images/Logo_SOG_Colour.png",
              },
            },
          }}
        />
        {article.faq && compileSchemaOrgQa(article.faq.faq)}
      </div>
    )
  }

  return (
    <div>
      <Layout>
        <div className="page-container">
          <div className={styles.articleContainer}>
            {/* <Link to="/credit-cards" className={styles.breadCrumb}>Credit Cards</Link> / <Link to="/articles" className={styles.breadCrumb}>Articles</Link> */}
            {articles.map(article => {
              const { title, lastUpdate, summary, author, readingTime, longText, faq, updatedAt, reference } = article
              const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
              // use updatedAt unless there is a value for lastUpdate
              const datetime = lastUpdate ? new Date(lastUpdate) : new Date(updatedAt)
              const date = datetime.getDate() + " " + monthNames[datetime.getMonth()].substring(0, 3) + " " + datetime.getFullYear()
              let processedLongText = longText.longText

              const view = {}
              const globalReference = JSON.parse(getGlobalReference().allContentfulArticleReferenceReferenceJsonNode.nodes[0].internal.content)
              Object.keys(globalReference).forEach(productName => {
                const productId = globalReference[productName]
                const product = AppManager.getInstance().getReducedCreditCardProductById(productId)
                if (Object.keys(product).length === 0) {
                  return
                }
                Object.keys(product).forEach((field: string) => {
                  view[productName + "_" + field] = product[field]
                })
              })

              if (reference) {
                const productsReference = JSON.parse(reference.internal.content)

                Object.keys(productsReference).forEach(productName => {
                  const productId = productsReference[productName]
                  const product = AppManager.getInstance().getReducedCreditCardProductById(productId)
                  if (Object.keys(product).length === 0) {
                    return
                  }
                  Object.keys(product).forEach((field: string) => {
                    view[productName + "_" + field] = product[field]
                  })
                })
              }
              if (Object.keys(view).length !== 0) {
                processedLongText = mustache.render(longText.longText, view)
              }

              const images = getImagesFromMarkdown(processedLongText)

              return (
                <div>
                  {makeSchemaOrg(article)}
                  <Typography.H1>{title}</Typography.H1>
                  <Typography.P>{summary}</Typography.P>
                  {author ? (
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      {author.profilePicture && <LazyLoadImage src={author.profilePicture.localFile.url} style={{width: "56px", height: "56px"}} />}
                      <div style={{ marginLeft: "15px" }}>
                        <Typography.P>{author.name}</Typography.P>
                        <Typography.P>{date + (readingTime ? " - " + readingTime + " min read" : "")}</Typography.P>
                      </div>
                    </div>
                  ) : readingTime ? (
                    <div style={{ marginTop: "30px" }}>
                      <Typography.P>{date + (readingTime ? " - " + readingTime + " min read" : "")}</Typography.P>
                    </div>
                  ) : null}
                  {longText ? <Markdown md={processedLongText} isArticle={true} /> : null}
                  {faq ? <Markdown md={faq.faq} isArticle={true} /> : null}
                  {/* {relatedArticles ? (
                    <div>
                      <div className={classes.listHeader}>
                        <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", weight: 700, lineHeight: "40px", fontSize: "20px" }}>Related Articles</div>
                      </div>
                      <div>{renderRichText(contentfulArticle.body, options)}</div>

                      <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(relatedArticles, options) }} />
                    </div>
                  ) : null} */}
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ArticleTemplate
